import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import { imageUrlFor } from '../../lib/image-url'
import { buildImageObj } from '../../lib/helpers'


import styles from './index.module.css';
import typography from '../typography.module.css'

let idNum = 0;
const idName = () => {
  idNum++;
  return (`bookmarkEle$(idNum)`);
}

///Make Columns
let stackElements = 0;
let stackElementsAlign = "";
let stackElementsClass = "";
const checkIfStacked = () => {

  let stackElementsClassName = `stackBlock${stackElementsClass}`;
  if (stackElements !=0 ){
    stackElements--;

    if(stackElementsAlign === 'center'){
      return (`${styles.stackBlock} ${styles.alignMid} ${styles[stackElementsClassName]}`)
    }else{
      return (`${styles.stackBlock} ${styles[stackElementsClassName]}`)
    }
  }else{
    return (styles.block)
  }
}

const serializers = {
  types: {
    block (props) {
      switch (props.node.style) {
        case 'h1':
          return <h1 className={`${styles.h1Header} ${checkIfStacked()}`}>{props.children}</h1>

        default:
          return (<p className={`${styles.paragraphCopy} ${checkIfStacked()}`}>{props.children}</p>)
      }
    },
    image (props) {
      const ifClass = (props.node.asset._ref.slice(-3) ==="png")? styles.imageNoBG : "";
      return (
        <div className={`${styles.imageBlock} ${checkIfStacked()}`}>
          <img
            src={imageUrlFor(buildImageObj(props.node))
              .maxWidth(1200)
              .url()}
              alt='Artwork Image' 
              className={ifClass}
              />          
        </div>
      )
    },
    figure (props) {
      const ifClass = (props.node.image.asset._ref.slice(-3) ==="png")? styles.imageNoBG : "";
      return (
        <div className={`${styles.imageBlock} ${checkIfStacked()}`}>
          <img
            src={imageUrlFor(buildImageObj(props.node.image))
              .maxWidth(1200)
              .url()}
              alt='Artwork Image' 
              className={ifClass}
              />          
        </div>
      )
    },
    videoLink (props) {
      if(!props.node.videoUrl) return null
      const ratio = (props.node.videoRatio)? props.node.videoRatio.split('x') : [16,9];
      const ratioWidth = (ratio[0]/ratio[1]*90) +"vh"
      const ratioHeight = (ratio[1]/ratio[0]*100) +"%"
      
      return (
        <div className={`${styles.videoBlock} ${checkIfStacked()}`} style={{maxWidth:ratioWidth}}>
          <div style={{paddingTop:ratioHeight}}>
            <iframe
              src={props.node.videoUrl}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              />
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      )
    },
    stackBlocks (props) {
      stackElementsAlign = props.node.style
      stackElements = props.node.number;
      stackElementsClass = props.node.number;
      return null
    }
  }, 
  marks: {
    alignCenter: ({ children, mark }) => (
      <span className={styles.alignCenter} > 
        {children}
      </span>
    )
  }
}


const BlockContent = ({ blocks, backgroundState }) => {
  return <BaseBlockContent blocks={blocks} className={styles.blockContainer} serializers={serializers}/>
}

export default BlockContent
