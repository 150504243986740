import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../../components/block-content'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'

import styles from '../../components/contact.module.css'

const ContactPage = props => {
  const { data, errors } = props
  const [inputSubject, inputSubjectChanged] = React.useState("");
  const [inputBody, inputBodyChanged] = React.useState("");

  const emailAddress = 'adelaide.reception@tbwa.com';
  const subjectChange = e => {inputSubjectChanged(e.target.value);}
  const bodyChange = e => {inputBodyChanged(e.target.value);}

  if (errors) {
    return (
      <Layout site='Adelaide'>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout  site='Adelaide'>
      <SEO title={'Contact'} />
      <Container>
        <div className={`${styles.container} ${styles.themeAdelaide}`}>
          <h1 className={`${styles.headerTitle} ${styles.themeAdelaide}`}>
            {page.title}
          </h1>

          <div className="formSection">
            <form>
              <div className={styles.grid} >
                <input className={`${styles.subject} theme-Adelaide`} name="subject" type="text" placeholder="Subject" onChange={ subjectChange }/>
                <textarea className={`${styles.body} theme-Adelaide`}  rows="10" name="body" placeholder="Message" onChange={ bodyChange }></textarea>
                <div className={`${styles.buttonWrapper} ${styles.themeAdelaide}`}>
                  <a className={`${styles.button} ${styles.buttonThemeAdelaide}`} href={`mailto:${emailAddress}?subject=${inputSubject}&body=${inputBody}`}>Send Message</a>
                </div>
              </div>
            </form>
          </div>

        </div>
      </Container>
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default ContactPage

export const query = graphql`
  query AdelaideContactPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      title
    }
  }
`